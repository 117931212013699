module controllers {
    export module costing {

        interface ICostParameterPurchaseOrderTypeScope extends ng.IScope {
        }

        export class costParameterPurchaseOrderTypeCtrl {

            static $inject = ["$scope",
            "$rootScope",
            "generalService",
            "$q",
            "bsLoadingOverlayService",
            "entityService",
            "$timeout",
            '$uibModal',
            'purchaseOrderTypeService',
            'costParameterPurchaseOrderTypeService',
            'costModelElementService',
            'currencyService'
            ];

            selectedPurchaseOrderType: interfaces.applicationcore.IDropdownModel;
            selectedEntity: interfaces.applicationcore.IDropdownModel;
            selectedEntityId: number=0;
            selectedPurchaseOrderTypeId: number;


            gridApi: uiGrid.IGridApi;
         
            allDirtyRows: Array<interfaces.costing.ICostPurchaseOrderType>;
            refresh: boolean = true;

            IsLoading: boolean = false;
            currentPage: number = 1;
            pageSize: number = 10;

            searchAccordian: boolean = false;
            showAllActive: boolean = true;

        
            constructor(private $scope: ICostParameterPurchaseOrderTypeScope, private $rootScope: interfaces.applicationcore.IRootScope,
                public generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService, private bsLoadingOverlayService,
                private entityService: interfaces.applicationcore.IEntityService,
                private $timeout: ng.ITimeoutService,
                private $uibModal: ng.ui.bootstrap.IModalService,
                private purchaseOrderTypeService: interfaces.purchase.IPurchaseOrderTypeService,
                private costParameterPurchaseOrderTypeService: interfaces.costing.ICostParameterPurchaseOrderTypeService,
                private costModelElementService: interfaces.costing.ICostModelElementService,
                private currencyService: interfaces.applicationcore.ICurrencyService,
            ) {
                this.loadPurchaseOrderTypesCosting(true);
            }

            loadPurchaseOrderTypes() {
                return this.purchaseOrderTypeService.getDropdownList().query({ownerEntityId:this.selectedEntityId},() => {

                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            }

            loadPurchaseOrderTypesForGrid(row: uiGrid.IGridRow) {
                if(row.entity.Entity){
                    return this.purchaseOrderTypeService.getDropdownList().query({ownerEntityId:row.entity.Entity.Id},() => {

                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
                }
            }

           

            purchaseordertypeChange(model: interfaces.applicationcore.IDropdownModel)
            {
                this.selectedPurchaseOrderType = model;
            }



            loadPurchaseOrderTypesCosting(onLoad: boolean){
                if(this.selectedPurchaseOrderType) {
                    this.selectedPurchaseOrderTypeId = this.selectedPurchaseOrderType.Id;
                } else {
                    this.selectedPurchaseOrderTypeId = 0;
                }

                if (onLoad)
                {
                    //this.selectedEntityId = this.$rootScope.Entity.Id;

                    this.entityService.getCurrentEntity().query((result: interfaces.applicationcore.IEntity) => {
                        this.selectedEntityId = result.Id;

                        this.bsLoadingOverlayService.wrap({
                            referenceId: 'purchaseordertype.costing'
                        },
                            () => {
                                return this.costParameterPurchaseOrderTypeService.getPurchaseOrderTypeList().query({
                                    entityId: 0,//this.selectedEntityId,
                                    purchaseordertypeId: this.selectedPurchaseOrderTypeId,
                                    showAllActive: this.showAllActive,
                                    numberRecords: this.pageSize,
                                    pageNumber: this.currentPage,
                                }, (result: Array<interfaces.costing.ICostPurchaseOrderType>) => {
                                    this.gvwPurchaseOrderType.data = result;

                                    if (result[0]) {
                                        this.gvwPurchaseOrderType.totalItems = result[0].NumRecs;
                                    } else {
                                        this.gvwPurchaseOrderType.totalItems = 0;
                                    }
                                }, (errorResponse) => {
                                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                                }).$promise;
                            });

                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    });
                }
                else
                {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'purchaseordertype.costing'
                    },
                        () => {
                            if(this.selectedEntity)
                            {
                                return this.costParameterPurchaseOrderTypeService.getPurchaseOrderTypeList().query({
                                    entityId: this.selectedEntity?this.selectedEntity.Id:0,// this.selectedEntity.Id,
                                    purchaseordertypeId: this.selectedPurchaseOrderTypeId,
                                    showAllActive: this.showAllActive,
                                    numberRecords: this.pageSize,
                                    pageNumber: this.currentPage,
                                }, (result: Array<interfaces.costing.ICostProduct>) => {
                                    this.gvwPurchaseOrderType.data = result;
    
                                    if (result[0]) {
                                        this.gvwPurchaseOrderType.totalItems = result[0].NumRecs;
                                    } else {
                                        this.gvwPurchaseOrderType.totalItems = 0;
                                    }
                                }, (errorResponse) => {
                                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                                }).$promise;
                            }
                            else
                            {
                                return this.costParameterPurchaseOrderTypeService.getPurchaseOrderTypeList().query({
                                    entityId:  this.selectedEntity?this.selectedEntity.Id:0,
                                    purchaseordertypeId: this.selectedPurchaseOrderTypeId,
                                    showAllActive: this.showAllActive,
                                    numberRecords: this.pageSize,
                                    pageNumber: this.currentPage,
                                }, (result: Array<interfaces.costing.ICostProduct>) => { 
                                    this.gvwPurchaseOrderType.data = result;
    
                                    if (result[0]) {
                                        this.gvwPurchaseOrderType.totalItems = result[0].NumRecs;
                                    } else {
                                        this.gvwPurchaseOrderType.totalItems = 0;
                                    }
                                }, (errorResponse) => {
                                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                                }).$promise;
                            }
                            
                        });
                }
            }

            loadEntities(searchText: string) {
                return this.entityService.getDropdownList(searchText).query(() => {

                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            }

            loadCostModels(row: uiGrid.IGridRow) {
                if(row.entity.Entity)
                {
                    return this.costModelElementService.getDropdownList(row.entity.Entity.Id).query({
                        ownerEntityId: row.entity.Entity.Id
                    }, () => {
                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
                }
            }

            entityChange(model: interfaces.applicationcore.IDropdownModel) {
                this.selectedEntity = model;
                if(model) {
                    this.selectedEntityId = model.Id;
                } else {
                    this.$rootScope.Entity.Id;
                }
            }  
            
            
            rowEntityChange(model: interfaces.applicationcore.IDropdownModel, row: uiGrid.IGridRow) {
                if (!model) {
                    row.entity.CostModelElement = undefined;
                }
            }

            loadCurrencies() {
                return this.currencyService.getCurrencyDropdownList().query(() => {
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            }

            public gvwPurchaseOrderType: uiGrid.IGridOptions = {
                data: [],
                enableSorting: true,
                enableFiltering: true,
                useExternalFiltering: false,
                enableCellEdit: false,
                useExternalSorting: false,
                multiSelect: false,
                enableColumnResizing: true,
                enableRowSelection: false,
                enableFullRowSelection: false,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                enablePinning: false,
                rowEditWaitInterval: -1,
                paginationPageSizes: [10, 20, 50, 100],
                paginationPageSize: 10,
                enableVerticalScrollbar: 1,
                useExternalPagination: true,
                onRegisterApi: (gridApi) => { this.registerGridApi(gridApi) },
                columnDefs: [{
                    name: "EDIT",
                    displayName: "",
                    field: "",
                    cellTemplate: `
                    <div class="GridButton"> 
                        <button type="button" ng-click="grid.appScope.costParameterPurchaseOrderTypeCtrl.removeInlinePurchaseOrderType(row.entity)" class="btn btn-warning btn-sm">
                            Delete
                        </button>
                    </div>`,
                    width: 55,
                    enableCellEdit: false,
                    enableCellEditOnFocus: false,
                    enableColumnMenu: false,
                    headerCellTemplate: `
                    <div class="GridButton center-block"> 
                        <button type="button" class="btn btn-default btn-sm" ng-click="grid.appScope.costParameterPurchaseOrderTypeCtrl.addInline()">
                            <span class="fa fa-plus"></span>
                        </button>
                    </div>`
                }, {
                    name: "ENTITY",
                    displayName: "* Entity",
                    field: "Entity",
                    enableCellEditOnFocus: true,
                    enableCellEdit: true,
                    width: 200,
                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD.Display}}">{{MODEL_COL_FIELD.Display}}</div>`,
                    editableCellTemplate: `
                                <form name="inputForm">
                                    <gts-dropdown ui-select-wrap name="{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.costParameterPurchaseOrderTypeCtrl.loadEntities(searchText)" ng-change="grid.appScope.costParameterPurchaseOrderTypeCtrl.rowEntityChange(model, row)"></gts-dropdown>
                                </form>`,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                    filter: {
                        condition: (searchTerm, cellValue) => {
                            return (cellValue.Display.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0 || searchTerm.length === 0);
                        }
                    },
                    sortingAlgorithm: (a, b, rowA, rowB, direction) => {
                        var nulls = this.gridApi.core.sortHandleNulls(a, b);
                        if (nulls !== null) {
                            return nulls;
                        } else {
                            if (a === b) {
                                return 0;
                            }

                            if (direction === "desc") {
                                if (a.Display < b.Display) return -1;
                                if (a.Display > b.Display) return 1;
                            } else if (direction === "asc") {
                                if (a.Display > b.Display) return 1;
                                if (a.Display < b.Display) return -1;
                            }

                            return 0;
                        }
                    },
                }, {
                    name: "ROUTE",
                    displayName: "* Purchase Order Type",
                    field: "PurchaseOrderType",
                    enableCellEditOnFocus: true,
                    enableCellEdit: true,
                    width: 210,
                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD.Display}}">{{MODEL_COL_FIELD.Display}}</div>`,
                    editableCellTemplate: `
                                <form name="inputForm">
                                    <gts-dropdown ui-select-wrap name="{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.costParameterPurchaseOrderTypeCtrl.loadPurchaseOrderTypesForGrid(row)"></gts-dropdown>
                                </form>`,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                    filter: {
                        condition: (searchTerm, cellValue) => {

                            if (cellValue)
                            {
                                return (cellValue.Display.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0 || searchTerm.length === 0);
                            }
                            return null;
                        }
                    },
                    sortingAlgorithm: (a, b, rowA, rowB, direction) => {
                        var nulls = this.gridApi.core.sortHandleNulls(a, b);
                        if (nulls !== null) {
                            return nulls;
                        } else {
                            if (a === b) {
                                return 0;
                            }

                            if (direction === "desc") {
                                if (a.Display < b.Display) return -1;
                                if (a.Display > b.Display) return 1;
                            } else if (direction === "asc") {
                                if (a.Display > b.Display) return 1;
                                if (a.Display < b.Display) return -1;
                            }

                            return 0;
                        }
                    },
                }, {
                    name: "COSTMODELELEMENT",
                    displayName: "* Cost Model Element",
                    field: "CostModelElement",
                    enableCellEditOnFocus: true,
                    enableCellEdit: true,
                    width: 220,
                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD.Display}}">{{MODEL_COL_FIELD.Display}}</div>`,
                    editableCellTemplate: `
                                <form name="inputForm">
                                    <gts-dropdown ui-select-wrap name="{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.costParameterPurchaseOrderTypeCtrl.loadCostModels(row)"></gts-dropdown>
                                </form>`,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                    filter: {
                        condition: (searchTerm, cellValue) => {
                            return (cellValue.Display.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0 || searchTerm.length === 0);
                        }
                    },
                    sortingAlgorithm: (a, b, rowA, rowB, direction) => {
                        var nulls = this.gridApi.core.sortHandleNulls(a, b);
                        if (nulls !== null) {
                            return nulls;
                        } else {
                            if (a === b) {
                                return 0;
                            }

                            if (direction === "desc") {
                                if (a.Display < b.Display) return -1;
                                if (a.Display > b.Display) return 1;
                            } else if (direction === "asc") {
                                if (a.Display > b.Display) return 1;
                                if (a.Display < b.Display) return -1;
                            }

                            return 0;
                        }
                    },
                }, {
                    name: "DATEFROM",
                    displayName: "* Date From",
                    field: "DateFrom",
                    enableCellEditOnFocus: true,
                    enableCellEdit: true,
                    width: 110,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | momentDateFilter: 'YYYY/MM/DD'}}</div>`,
                    editableCellTemplate: `
                        <form name="inputForm">
                            <gts-date format="yyyy/MM/dd" ng-model="MODEL_COL_FIELD" name="DateFrom-{{MODEL_COL_FIELD.$$hashKey}}"></gts-date>
                        </form>`,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <gts-date format="yyyy/MM/dd" ng-model="colFilter.term" name="dateFrom"></gts-date>
                            </div>`,
                    filter: {
                        condition: function (searchTerm, cellValue) {
                            if (cellValue) {
                                return cellValue.isSame(searchTerm);
                            } else {
                                return false;
                            }
                        }
                    },
                }, {
                    name: "DATETO",
                    displayName: "Date To",
                    field: "DateTo",
                    enableCellEditOnFocus: true,
                    enableCellEdit: true,
                    width: 110,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | momentDateFilter: 'YYYY/MM/DD'}}</div>`,
                    editableCellTemplate: `
                        <form name="inputForm">
                            <gts-date format="yyyy/MM/dd" ng-model="MODEL_COL_FIELD" name="DateTo-{{MODEL_COL_FIELD.$$hashKey}}"></gts-date>
                        </form>`,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                <gts-date format="yyyy/MM/dd" ng-model="colFilter.term" name="dateTo"></gts-date>
                                        
                            </div>`,
                    filter: {
                        condition: function (searchTerm, cellValue) {
                            if (cellValue) {
                                return cellValue.isSame(searchTerm);
                            } else {
                                return false;
                            }
                        }
                    },
                }, {
                    name: "RATE",
                    displayName: "* Rate",
                    field: "Rate",
                    enableCellEditOnFocus: true,
                    enableCellEdit: true,
                    width: 120,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | number:6}}</div>`,
                    editableCellTemplate: `
                                <form name="inputForm">
                                    <p class="input-group-sm">
                                        <input type="number" ignore-wheel name="Rate-{{MODEL_COL_FIELD.$$hashKey}}" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor step="0.000001" />
                                    </p>
                                </form>`,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                }, {
                    name: "CURRENCY",
                    displayName: "Currency",
                    field: "Currency",
                    enableCellEditOnFocus: true,
                    enableCellEdit: true,
                    width: 165,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD.Display}}</div>`,
                    editableCellTemplate: `
                                <form name="inputForm">
                                    <gts-dropdown ui-select-wrap name="{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.costParameterPurchaseOrderTypeCtrl.loadCurrencies()"></gts-dropdown>
                                </form>`,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                    filter: {
                        condition: (searchTerm, cellValue) => {
                            if (cellValue) {
                                return (cellValue.Code.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0 || searchTerm.length === 0);
                            }
                            return null;
                        }
                    },
                    sortingAlgorithm: (a, b, rowA, rowB, direction) => {
                        var nulls = this.gridApi.core.sortHandleNulls(a, b);
                        if (nulls !== null) {
                            return nulls;
                        } else {
                            if (a === b) {
                                return 0;
                            }

                            if (direction === "desc") {
                                if (a.Code < b.Code) return -1;
                                if (a.Code > b.Code) return 1;
                            } else if (direction === "asc") {
                                if (a.Code > b.Code) return 1;
                                if (a.Code < b.Code) return -1;
                            }

                            return 0;
                        }
                    },
                }, {
                    name: "ACTIVE",
                    displayName: "Is Active",
                    field: "IsActive",
                    width: 75,
                    enableCellEdit: true,
                    enableCellEditOnFocus: true,
                    cellTemplate: '<input type="checkbox" ng-model="MODEL_COL_FIELD" ng-change="grid.appScope.costParameterPurchaseOrderTypeCtrl.checkBoxChanged(row.entity)">',
                    editableCellTemplate: `
                                    <form name="inputForm">
                                        <p class="input-group-sm">
                                            <input type="checkbox" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" ui-grid-editor>
                                        </p>
                                    </form>`,
                    filterHeaderTemplate: `
                                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                            <div class="input-group-sm">
                                                <input type="checkbox" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                        </div>`,
                    filter: {
                        condition: (searchTerm, cellValue) => {
                            return searchTerm == cellValue;
                        }
                    },
                }, {
                    name: "INFO",
                    displayName: "",
                    field: "",
                    cellTemplate: `
                            <div class="GridButton"> 
                                <gts-stamp-info createstampfullname="row.entity.CreateStampFullName" createstampdate="row.entity.CreateStampDate"
                                    updatestampfullname="row.entity.UpdateStampFullName" updatestampdate="row.entity.UpdateStampDate">
                                </gts-stamp-info>
                            </div>`,
                        width: 38,
                    enableSorting: false,
                    enableCellEdit: false,
                    enableCellEditOnFocus: false,
                    enableColumnMenu: false,
                    enableFiltering: false,
                }, {
                    name: "NUMRECS",
                    displayName: "",
                    field: "NumRecs",
                    visible: false
                }]
            };

            public registerGridApi(gridApi: uiGrid.IGridApi) {
                this.gridApi = gridApi;

                this.gridApi.pagination.on.paginationChanged(this.$scope, (newPage, pageSize) => { this.ListPageChange(newPage, pageSize) });
            }

            removeInlinePurchaseOrderType(entity: interfaces.tariff.IProductTypeDisplay) {
                if (entity.Id > 0)
                {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'purchaseordertype.costing'
                    }, () => {
                        return this.costParameterPurchaseOrderTypeService.removePurchaseOrderType().delete({
                            cpuId: entity.Id,
                        }, (result) => {
                            this.$timeout(() => {
                                this.generalService.displayMessageHandler(result);
                                this.loadPurchaseOrderTypesCosting(false);
                            });
                        }, (errorResponse) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        }).$promise;
                    });
                }
                else
                {
                    _.remove(this.gvwPurchaseOrderType.data, (o) => {
                        return o === entity;
                    });
                }
            }

            ListPageChange(newPage, pageSize) {
                
                var dirtyRows = _.map(this.gridApi.rowEdit.getDirtyRows(), (value) => { return value.entity; });

                if (this.allDirtyRows)
                {
                    _.forEach(dirtyRows, (value) => {

                        //If a dirty row exists in the new dirty rows then overwrite the old one.
                        var row: interfaces.costing.ICostPurchaseOrderType = _.find(this.allDirtyRows, _.matchesProperty('Id', value.Id));

                        if (row)
                        {
                            //Row exists with the same Id.
                            var i: number = _.indexOf(this.allDirtyRows, row);
                            this.allDirtyRows[i] = row;
                        }
                        else
                        {
                            this.allDirtyRows = _.concat(this.allDirtyRows, value);
                        }

                    });
                }
                else
                {
                    this.allDirtyRows = dirtyRows;
                }
                                
                this.currentPage = newPage;
                this.pageSize = pageSize;

                this.DoSearch();
            }

            DoSearch() {
                return this.costParameterPurchaseOrderTypeService.getPurchaseOrderTypeList().query({
                    entityId: this.selectedEntity?this.selectedEntity.Id : 0,//  this.selectedEntityId,
                    purchaseordertypeId: this.selectedPurchaseOrderTypeId,
                    showAllActive: this.showAllActive,
                    numberRecords: this.pageSize,
                    pageNumber: this.currentPage,
                }, (result: Array<interfaces.costing.ICostPurchaseOrderType>) => {

                    var currentDirtyRows: Array<interfaces.costing.ICostPurchaseOrderType> = [];

                    _.forEach(this.allDirtyRows, function (value) {
                        var row: interfaces.costing.ICostPurchaseOrderType = _.find(result, _.matchesProperty('Id', value.Id));

                        if (row)
                        {
                            var i: number = _.indexOf(result, row);
                            result[i] = value;
                            currentDirtyRows.push(result[i]);
                        }
                    });

                    this.gvwPurchaseOrderType.data = result;

                    if (currentDirtyRows.length > 0) {
                        this.$timeout(() => {
                            _.forEach(currentDirtyRows, (value) => {
                                this.gridApi.rowEdit.setRowsDirty(currentDirtyRows);
                            });
                        });
                    }
                                
                    if (result[0]) {
                        this.gvwPurchaseOrderType.totalItems = result[0].NumRecs;
                    } else {
                        this.gvwPurchaseOrderType.totalItems = 0;
                    }
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                });
            }
            
            checkBoxChanged(entity) {
                if (this.gridApi) {
                    this.gridApi.rowEdit.setRowsDirty([entity]);
                }
            }
            
            addInline() {
                (<Array<any>>(this.gvwPurchaseOrderType.data)).splice(0, 0, {
                    IsActive: true
                });
            }

            submit_click() {
                var dirtyRows: Array<interfaces.costing.ICostPurchaseOrderType>;
                dirtyRows = _.map(this.gridApi.rowEdit.getDirtyRows(), (value) => { return value.entity; });

                if (this.allDirtyRows) {
                    _.forEach(dirtyRows, (value) => {

                        //If a dirty row exists in the new dirty rows then overwrite the old one.
                        var row: interfaces.costing.ICostPurchaseOrderType = _.find(this.allDirtyRows, _.matchesProperty('Id', value.Id));

                        if (row) {
                            //Row exists with the same Id.
                            var i: number = _.indexOf(this.allDirtyRows, row);
                            this.allDirtyRows[i] = row;
                        }
                        else {
                            this.allDirtyRows = _.concat(this.allDirtyRows, value);
                        }

                    });
                }
                else {
                    this.allDirtyRows = dirtyRows;
                }

                if (this.allDirtyRows.length > 0) {

                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'purchaseordertype.costing'
                    },
                        () => {
                            //Get all the dirty rows and save them.
                            return this.costParameterPurchaseOrderTypeService.savePurchaseOrderType().save(this.allDirtyRows, (result: interfaces.applicationcore.IMessageHandler) => {
                                this.generalService.displayMessageHandler(result);

                                if (!result.HasErrorMessage)
                                {
                                    this.allDirtyRows = [];

                                    this.refresh = false;

                                    this.$timeout(() => {
                                        this.refresh = true;
                                        this.DoSearch();
                                    });
                                }
                                
                                this.allDirtyRows = [];
                            }, (errorResponse) => {
                                this.allDirtyRows = [];
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                            }).$promise;
                        });
                }
            }



        

        }

        angular.module("app").controller("costParameterPurchaseOrderTypeCtrl", controllers.costing.costParameterPurchaseOrderTypeCtrl);

    }
}